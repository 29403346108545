import JSEncrypt from 'jsencrypt';

const encryptFields = () => {
  const encrypt = new JSEncrypt();
  const encryptKeyElement = document.getElementById('js-public_key');
  if (encryptKeyElement === null) return;

  const encryptKey = encryptKeyElement.dataset.value;
  encrypt.setKey(encryptKey);

  const fieldsToEncrypt = document.querySelectorAll('[data-encrypt]');

  fieldsToEncrypt.forEach((field) => {
    const unencrypted = field;
    const encryptedValue = encrypt.encrypt(field.value);
    if (encryptedValue) {
      unencrypted.value = encryptedValue;
    }
  });
};

const encryptFormOnSubmit = (form) => {
  form.addEventListener('submit', encryptFields);
};

const initEncryption = () => {
  const forms = document.querySelectorAll('form');
  forms.forEach(encryptFormOnSubmit);
};

window.encryptFormOnSubmit = encryptFormOnSubmit;

document.addEventListener('turbolinks:load', initEncryption);
